<script>
  import { getContext } from "svelte";
  import { scaleCanvas } from "layercake";
  import { scaleLinear } from "d3-scale";
  import canvasTxt from "canvas-txt";

  export let n;
  export let tf;
  export let tt;
  export let dt;
  export let o;
  export let r;
  export let phiPlay;
  export let thetaPlay;
  export let message;
  export let showLogo;

  const { width, height } = getContext("LayerCake");
  const { ctx } = getContext("canvas");

  $: ratio = $width / 1200;

  $: history.replaceState(
    null,
    null,
    `${location.origin}${location.pathname}?n=${n}&tf=${(
      Math.round(tf * 100) / 100
    ).toFixed(2)}&tfp=${thetaPlay}&tt=${(Math.round(tt * 100) / 100).toFixed(
      2
    )}&ttp=${phiPlay}&dt=${dt}&o=${o}&r=${r}&m=${encodeURIComponent(
      btoa(message)
    )}`
  );

  $: radius = scaleLinear().domain([-400, 400]).range([1, r]);

  const drawEllipse = (x, y, z) => {
    const gradient = $ctx.createRadialGradient(
      x,
      y,
      0,
      x,
      y,
      Math.sqrt(ratio) * radius(z)
    );
    gradient.addColorStop(0, `rgba(255, 255, 255, ${ratio * 0.1})`);
    gradient.addColorStop(1, `rgba(255, 255, 255, ${o * ratio})`);

    $ctx.fillStyle = gradient;

    $ctx.beginPath();
    $ctx.ellipse(
      x,
      y,
      Math.sqrt(ratio) * radius(z),
      Math.sqrt(ratio) * radius(z),
      0,
      0,
      Math.PI * 2
    );
    $ctx.closePath();
    $ctx.fill();
  };

  const img = new Image();
  let imageLoaded = false;

  const logoImage = new Image();
  let logoImageLoaded = false;

  let t = 0;

  const draw = () => {
    if ($ctx) {
      scaleCanvas($ctx, $width, $height);

      $ctx.globalCompositeOperation = "source-over";

      const backgroundGradient = $ctx.createLinearGradient(0, 0, 0, $height);
      backgroundGradient.addColorStop(0, "rgb(0 25 54");
      backgroundGradient.addColorStop(1, "rgb(0 9 16)");

      $ctx.fillStyle = backgroundGradient;
      $ctx.fillRect(0, 0, $width, $height);

      $ctx.globalCompositeOperation = "lighten";

      // center
      $ctx.translate($width * 0.5, $height * 0.5);

      const r = $width / 3;

      $ctx.strokeStyle = "rgba(4, 66, 136, 0.3)";
      $ctx.ellipse(0, 0, r, r, 0, 0, Math.PI * 2);
      $ctx.stroke();

      $ctx.save();

      $ctx.beginPath();
      $ctx.ellipse(0, 0, r, r, 0, 0, Math.PI * 2);
      $ctx.clip();

      const gradient = $ctx.createRadialGradient(
        -150 * ratio,
        -150 * ratio,
        100 * ratio,
        0,
        0,
        400 * ratio
      );
      gradient.addColorStop(0, "rgba(4, 66, 136, 0.5)");
      gradient.addColorStop(0.9, "rgba(0, 11, 24, 0.6)");
      gradient.addColorStop(1, "rgba(0, 22, 60, 0.8)");

      $ctx.fillStyle = gradient;
      $ctx.beginPath();
      $ctx.ellipse(0, 0, r, r, 0, 0, Math.PI * 2);
      $ctx.closePath();
      $ctx.fill();

      // $ctx.beginPath()
      for (let i = 0; i < n; i++) {
        const phi = ((i * tt) / n) * Math.PI * 2 + t * (Math.PI * 2);
        const theta = ((i * tf) / n) * Math.PI + t * (Math.PI * 2);
        const x = r * Math.cos(phi) * Math.sin(theta);
        const y = r * Math.sin(phi) * Math.sin(theta);
        const z = r * Math.cos(theta);

        drawEllipse(x, y, z);
      }

      $ctx.restore();

      t += dt;

      if (thetaPlay) {
        if (tf > 256) {
          tf = 0;
        }
        tf += dt;
      }

      if (phiPlay) {
        if (tt > 256) {
          tt = 0;
        }
        tt += dt;
      }

      if (imageLoaded) {
        $ctx.globalCompositeOperation = "source-over";
        $ctx.drawImage(
          img,
          -520 * ratio,
          150 * ratio,
          ratio * (908 / 4),
          ratio * (1046 / 4)
        );
      }

      $ctx.fillStyle = "white";
      $ctx.shadowColor = "rgb(0 25 54)";
      $ctx.shadowBlur = 15 * ratio;
      canvasTxt.font = "Asar";
      canvasTxt.fontSize = 48 * ratio;
      canvasTxt.align = "center";
      canvasTxt.vAlign = "middle";
      canvasTxt.fontWeight = "bold";
      canvasTxt.debug = false;
      canvasTxt.drawText(
        $ctx,
        message,
        ratio * -300,
        ratio * -410,
        ratio * 600,
        ratio * 800
      );

      if (logoImageLoaded && showLogo) {
        $ctx.globalCompositeOperation = "source-over";
        $ctx.filter = "invert(1)";
        $ctx.globalAlpha = 0.2;
        $ctx.drawImage(
          logoImage,
          ratio * (-logoImage.width / 3 / 2),
          ratio * 450,
          ratio * (logoImage.width / 3),
          ratio * (logoImage.height / 3)
        );
      }
    }
    window.requestAnimationFrame(draw);
  };

  img.onload = function () {
    imageLoaded = true;
  };
  img.src = "../img/attachment.png";

  logoImage.onload = function () {
    logoImageLoaded = true;
  };
  logoImage.src = "../img/logo_tulpinteractive.png";

  draw();
</script>
