<script>
  import Message from "./Message.svelte";
  import TheThing from "./TheThing.svelte";
  import { Canvas, LayerCake, Html } from "layercake";
  import { slide } from "svelte/transition";
  import canvasToImage from "canvas-to-image";
  import SvelteSeo from "svelte-seo";

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  let message = `Happy Holidays and best wishes for a healthy, happy, and peaceful 2022!
  
Jan Willem Tulp`;

  let n = 647;
  // let tf = 30.2;
  // let tt = 43.1;
  let tf = 143.9;
  let tt = 120.4;
  let dt = 0.0007;
  let o = 0.03;
  let r = 35;
  let phiPlay = false;
  let thetaPlay = false;
  let showLogo = false;

  const nExtent = [0, 2000];
  const tfExtent = [0, 256];
  const ttExtent = [0, 256];
  const dtExtent = [0, 0.01];
  const oExtent = [0, 0.1];
  const rExtent = [1, 50];

  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has("n")) {
    n = clamp(+urlParams.get("n"), nExtent[0], nExtent[1]) || 200;
  }

  if (urlParams.has("tf")) {
    tf = clamp(+urlParams.get("tf"), tfExtent[0], tfExtent[1]) || 186;
  }

  if (urlParams.has("tt")) {
    tt = clamp(+urlParams.get("tt"), ttExtent[0], ttExtent[1]) || 128;
  }

  if (urlParams.has("dt")) {
    dt = clamp(+urlParams.get("dt"), dtExtent[0], dtExtent[1]) || 0.0005;
  }

  if (urlParams.has("o")) {
    o = clamp(+urlParams.get("o"), oExtent[0], oExtent[1]) || 0.03;
  }

  if (urlParams.has("r")) {
    r = clamp(+urlParams.get("r"), rExtent[0], rExtent[1]) || 35;
  }

  if (urlParams.has("m")) {
    try {
      message = decodeURIComponent(atob(urlParams.get("m")));
    } catch (e) {
      console.error(e);
      message = "Merry Christmas and a Happy New Year!";
    }
  }

  if (urlParams.has("tfp")) {
    thetaPlay = urlParams.get("tfp") === "true" ? true : false;
  }

  if (urlParams.has("ttp")) {
    phiPlay = urlParams.get("ttp") === "true" ? true : false;
  }

  let showControlPanel = true;

  $: history.replaceState(
    null,
    null,
    `${location.origin}${
      location.pathname
    }?n=${n}&tf=${tf}&tfp=${thetaPlay}&tt=${tt}&ttp=${phiPlay}&dt=${dt}&o=${o}&r=${r}&m=${encodeURIComponent(
      btoa(message)
    )}`
  );
</script>

<SvelteSeo
  openGraph={{
    title: "Happy Holidays 2021 from TULP interactive",
    description:
      "Happy Holidays and best wishes for a healthy, happy, and peaceful 2022! — Jan Willem Tulp | TULP interactive",
    url: "http://tulpinteractive.com/happy-holidays-2021",
    type: "website",
    images: [
      {
        url: "img/og-image.jpg",
        width: 2400,
        height: 650,
        alt: "Happy Holidays 2021 from TULP interactive",
      },
    ],
  }}
/>

<main>
  <LayerCake>
    <Canvas>
      <TheThing
        {n}
        {tf}
        {tt}
        {dt}
        {o}
        {r}
        {phiPlay}
        {thetaPlay}
        {message}
        {showLogo}
      />
    </Canvas>
    <!-- <Html>
      <Message {message} />
    </Html> -->
  </LayerCake>
</main>

<a
  href="http://tulpinteractive.com"
  target="_blank"
  title="TULP interactive"
  class="logo"
>
  <img src="img/logo_tulpinteractive.png" alt="TULP interactive" />
</a>

<div class="control-panel">
  <button class="toggle" on:click={() => (showControlPanel = !showControlPanel)}
    >{#if showControlPanel}hide{:else}show{/if} control panel</button
  >
  {#if showControlPanel}
    <div class="inner" transition:slide>
      <div>message: <textarea rows={7} bind:value={message} /></div>
      <div class="param">
        <span>N:</span><input
          type="range"
          class="slider"
          bind:value={n}
          min={nExtent[0]}
          max={nExtent[1]}
        />
      </div>
      <div class="param">
        <span>θ:</span><button
          class="play-button"
          on:click={() => (thetaPlay = !thetaPlay)}
          >{#if thetaPlay}<i class="material-icons">pause</i>{:else}<i
              class="material-icons">play_arrow</i
            >{/if}</button
        ><input
          type="range"
          class="slider"
          bind:value={tf}
          min={tfExtent[0]}
          max={tfExtent[1]}
          step={0.1}
        />
      </div>
      <div class="param">
        <span>Φ:</span><button
          class="play-button"
          on:click={() => (phiPlay = !phiPlay)}
          >{#if phiPlay}<i class="material-icons">pause</i>{:else}<i
              class="material-icons">play_arrow</i
            >{/if}</button
        ><input
          type="range"
          class="slider"
          bind:value={tt}
          min={ttExtent[0]}
          max={ttExtent[1]}
          step={0.1}
        />
      </div>
      <div class="param">
        <span>speed:</span><input
          type="range"
          class="slider"
          bind:value={dt}
          min={dtExtent[0]}
          max={dtExtent[1]}
          step={0.0001}
        />
      </div>
      <div class="param">
        <span>opacity:</span><input
          type="range"
          class="slider"
          bind:value={o}
          min={oExtent[0]}
          max={oExtent[1]}
          step={0.0001}
        />
      </div>
      <div class="param">
        <span>radius:</span><input
          type="range"
          class="slider"
          bind:value={r}
          min={rExtent[0]}
          max={rExtent[1]}
        />
      </div>
      <button
        class="screenshot"
        on:click={() => {
          showLogo = true;

          setTimeout(() => {
            canvasToImage(
              document.getElementsByClassName("layercake-layout-canvas")[0],
              {
                name: `Happy Holidays 2021 from TULP interactive - ${new Date().toISOString()}`,
                type: "jpg",
                quality: 1,
              }
            );

            showLogo = false;
          }, 1000);
        }}>save a screenshot</button
      >
    </div>
  {/if}
</div>

<style>
  .screenshot {
    width: 100%;
    border: none;
    outline: none;
    background: rgb(255, 14, 47);
    color: white;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
  }

  .screenshot:hover {
    background: rgb(255, 55, 82);
  }
  .logo {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    filter: invert();
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
  }

  .logo:hover img {
    opacity: 0.9;
  }

  .logo img {
    width: 100%;
    opacity: 0.5;
  }

  main {
    width: 100%;
    max-width: 1200px;
    height: 120vw;
    max-height: 100%;
    margin: 0 auto;
  }

  .toggle {
    color: white;
    padding: 10px;
    width: 170px;
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
  }
  .play-button {
    border: none;
    outline: none;
    background: none;
    /* color: white; */
    color: rgba(255, 14, 47, 0.93);
    cursor: pointer;
    position: relative;
    top: 6px;
    padding: 0;
  }

  textarea {
    width: 100%;
    margin-bottom: 10px;
    outline: none;
  }
  input {
    width: 100%;
    margin-bottom: 10px;
    outline: none;
  }

  .control-panel {
    font-family: "NTR", sans-serif;
    position: absolute;
    bottom: 0;
    right: 0;
    display: grid;
    justify-items: end;
    max-width: 500px;
    color: white;
    z-index: 1000;
  }

  .inner {
    background: black;
    border-radius: 5px;
    padding: 30px;
  }

  .param {
    margin: 0 0 10px 0;
  }
</style>
